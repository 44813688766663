import { post } from '@/services/api'
import Auth from '@/services/Auth'

export default class Print {

  constructor(store) {
    this.store = store;
  }

// STATES
  manualPrinterSet() {
    return localStorage.getItem('manualPrint') !== null || this.store.getters.getManualPrinter !== null;
  }

  get autoLocalSet() {
    let useAutoDefaults = localStorage.getItem('autoPrint');
    return useAutoDefaults !== null
  }

  get manualLocalSet() {
    let useManualDefaults = localStorage.getItem('manualPrint')
    return useManualDefaults !== null
  }

// GLOBAL SETTINGS HANDLING
  saveGlobalDefaults(type) {
    let savePrintData = this.store.getters.getDefaultGlobals || {};
    if(type == 'auto')
      savePrintData.automated = this.store.getters.getAutoSettings
    if(type == 'manual')
      savePrintData.manual = this.store.getters.getManualSettings

    return new Promise((resolve, reject) => {
      post( `/update_print_settings/`, savePrintData)
           .then(() => {
             resolve();
           })
           .catch((err) => {
             reject(err)
           })
    });
  }


  preparePrinterData(printer) {
    return {
        printer: printer.identifier,
        computer: printer.computer.uuid,
        computer_name: printer.computer.name
      }
  }

  processQueue(printQueue) {
      const settings = this.store.getters.getManualSettings;
      const printer = this.store.getters.getPrinterById(settings.printer);

      if(printer) {
        const printerData = this.preparePrinterData(printer)
        return this.processAllPrintData(printQueue, settings, printerData);
      }
  }

  // API now supports multiple products with the same code, we add copies to the data
  prepareProductData(products, single) {
    //We print one label for each product separately
    if(!single)
      return products.reduce((productData, product) => {
        productData.push( { print: {data_source: { shoptet_product: product.code }, copies: product.amount }, type: 'product' });
        return productData;
      }, [])
    //We print only one label but with the amount of products in the template
    else return products.map(p => ({ print: {data_source: { shoptet_product: p.code}, copies: 1, data: { amount: p.amount }}, type: 'product' }));

  }

  // AUTOMATIC PRINT ON SCAN
  handleAutoPrint({products, location}) {
    const printDisabled = !localStorage.getItem('printOnScan') || localStorage.getItem('printOnScan') == "false";
    if(process.env.NODE_ENV == 'TESTING' || window.Cypress || printDisabled)
      return Promise.resolve();

    const settings = this.store.getters.getAutoSettings;
    let promise;

    // Prepare data based on what is allowed in settings
    if(settings.product === true) {
      const productDataForPrinting = this.prepareProductData(products, settings.productSingle);
      const productPrinter = this.store.getters.getPrinterById(settings.productPrinter);
      if(productPrinter) {
        const productPrinterData = this.preparePrinterData(productPrinter);
        promise = this.processAllPrintData(productDataForPrinting, settings, productPrinterData);
      }
    }

    if(settings.location === true)  {
      const locationDataForPrinting = [{print: {data_source: { stock_position: location }}, type: 'location' }]
      const locationPrinter = this.store.getters.getPrinterById(settings.locationPrinter);
      if(locationPrinter) {
        const locationPrinterData = this.preparePrinterData(locationPrinter);
        promise = this.processAllPrintData(locationDataForPrinting, settings, locationPrinterData);
      }
    }

    if(promise)
      return promise;
    else
      return Promise.resolve();
  }


  async processAllPrintData(printQueue, settings, printerData) {
    console.log('processAllPrintData', printQueue, settings, printerData);
    // Loop through all print data and put them into one big dataset for printing
    // this is the data array that will be sent to the print API
    const printDataForPDF = await printQueue.reduce((dataForPDF, data) => {
      // If we have a print object, we parse it and add it to the data array
      if(data.print) {
        const templateToUse = data.type == 'product' ? settings.productTemplate : settings.locationTemplate;
        dataForPDF.push({ ...data.print, template_id: templateToUse, eshop_id: Auth.userParameter('stock_eshop_id') });
      } else {
        // If we received alredy prepared data, we just add it to the array
        dataForPDF.push(data);
      }
      return dataForPDF;
    }, [])

    const pdfUrl = await this.generatePDF(printDataForPDF);
    return this.print({ url: pdfUrl, ...printerData });
  }

  generatePDF(dataSource) {
    console.log('generatePDF', dataSource);  
    return new Promise((resolve, reject) => {
      post( process.env.VUE_APP_PRINT_API + `/generate_v2/`,{ 
        data: [
          ...dataSource
        ],
        copies: 1,
      }).then((res) => {
        resolve(res.data.pdf);
      })
      .catch((err) => {
        reject(err)
      })
    })
  }

  
            

  print(printerData) {
    return new Promise((resolve, reject) => {
    post( process.env.VUE_APP_PRINT_SERVER + '/request-print/', printerData)
         .then((res) => {
           resolve(res.data)
         })
         .catch((err) => {
           reject(err)
         })
    })
  }


}


Print.install = (Vue, options) => {
    const print = new Print(options.store);
    Vue.$print = print;
    Vue.prototype.$print = print;
};
