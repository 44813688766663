import Rollbar from 'rollbar'
import Vue from 'vue'
import moment from 'moment'
import VueGtm from '@gtm-support/vue2-gtm';

import './filters/Pluralize'

import router from './router'
import App from './App.vue'
import store from './store/'
import vuetify from './plugins/vuetify'

import EshopConfig from "./plugins/eshopConfig";
import Sound from "./plugins/Sounds";
import RevertAction from "./plugins/revertAction"
import Sanitize from "./plugins/Sanitize"
import Print from "./plugins/print"
import Dialog from "@/plugins/Dialog"
import ScanLoadingSpinner from "./plugins/ScanLoadingSpinner"

import PageTitle from '@/components/PageTitle'
import Snack from "@/plugins/Snackbar"
import OneLineTooltip from '@/components/OneLineTooltip'
import ConfirmDialog from '@/components/ConfirmDialog'
import ScanLoadingSpinnerComp from "./components/ScanLoadingSpinner"

import 'vuetify/dist/vuetify.min.css'


Vue.config.productionTip = false
Vue.prototype.moment = moment
Vue.use(Sanitize);
Vue.use(EshopConfig, {store});
Vue.use(Sound);
Vue.use(RevertAction);
Vue.use(Print, {store});
Vue.use(Snack, {store});
Vue.use(Dialog, {store});
Vue.use(ScanLoadingSpinner, {store});

Vue.component('PageTitle', PageTitle);
Vue.component('OneLineTooltip', OneLineTooltip);
Vue.component('ConfirmDialog', ConfirmDialog);
Vue.component('ScanLoadingSpinner', ScanLoadingSpinnerComp);

// ROLLBAR LOGGING
if (process.env.NODE_ENV !== "development") {
  Vue.prototype.$rollbar = new Rollbar({
    accessToken: 'd86bfc41f0484fc282d39ea0527ee5b3',
    captureUncaught: true,
    captureUnhandledRejections: true,
    itemsPerMinute: 5,
    captureIp: 'anonymize',
    addErrorContext: true,
    ignoredMessages: ['error getting `reason` from event', 'Request aborted'],
    payload: {
      environment: process.env.NODE_ENV,
      custom: {
        eshop: Vue.$eshop.getName(),
      },
    }
  });
  Vue.config.errorHandler = (err, vm) => {
    vm.$rollbar.error(err);
    throw err; // rethrow
  };
}

//GTM
Vue.use(VueGtm, {
  id: 'GTM-NB4L3KX5',
  compatibility: false,
  loadScript: true,
  enabled: process.env.NODE_ENV !== "development",
  debug: process.env.NODE_ENV == "development",
  trackOnNextTick: true,
});

const app = new Vue({
  store,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')

if (window.Cypress) {
  // only available during E2E tests
  window.app = app;
}
