import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    meta: {
      permissions: 'sklad.log_pohybu'
    },
  },
  {
    path: '/exports',
    name: 'Exports',
    component: () => import('@/views/Exports/Exports'),
    meta: {
      permissions: 'sklad.export_import'
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login'),
  },
  {
    path: '/movementLog',
    name: 'movementLog',
    component: () => import('@/views/MovementLog'),
    meta: {
      permissions: 'sklad.log_pohybu'
    },
  },
  {
    path: '/inventory',
    name: 'inventory',
    component: () => import('@/views/Sectors/Inventory'),
    children: [
       {
         path: 'overview',
         name: 'overview',
         component: () => import('@/views/Sectors/InventoryOverview'),
         meta: {
            permissions: 'sklad.nahled_na_sklad'
          },
       },
       {
         path: 'layout',
         name: 'layout',
         component: () => import('@/views/Sectors/InventoryLayoutChange'),
        meta: {
            permissions: 'sklad.reorganizace_skladu'
          },
       }
    ]
  },
  {
    path: '/products/add-ao/:shoplist?',
    name: 'products-ao',
    component: () => import('@/views/Products/ProductsAO'),
    meta: {
      permissions: 'sklad.naskladneni_ao'
    },
  },
  {
    path: '/products/:actionType',
    name: 'products',
    component: () => import('@/views/Products/Products'),
    meta: {
      permissions: {
        add: 'sklad.naskladneni',
        remove: 'sklad.vyskladneni',
        'no_sync_add': 'sklad.naskladneni_vratek',
        'no_sync_remove': 'sklad.vyskladneni_vratek',
        'location_check': 'sklad.overeni_pozice',
        'location_change': 'sklad.zmena_pozic',
      }
    },
  },
  {
    path: '/locations/chaos_change',
    name: 'chaosLocations',
    component: () => import('@/views/Products/ChaosLocations'),
    meta: {
      permissions: 'sklad.zmena_pozic'
    },
  },
  {
    path: '/locations/relocation_rules',
    name: 'relocationRules',
    component: () => import('@/views/Products/RelocationRules'),
    meta: {
      permissions: 'sklad.pravidla_pro_presun'
    },
  },
  {
    path: '/stocktaking',
    name: 'stocktaking',
    component: () => import('@/views/StockTaking/StockTakingList'),
    meta: {
      permissions: 'sklad.inventura'
    },
  },
  {
    path: '/stocktaking/detail/:id',
    name: 'stockTakingsDetail',
    component: () => import('@/views/StockTaking/StockTakingDetail'),
    meta: {
      permissions: 'sklad.inventura'
    },
  },
  {
    path: '/eans',
    name: 'eanSettings',
    component: () => import('@/views/EanSettings'),
    meta: {
      permissions: 'sklad.multiean_krabice'
    },
  },
  {
    path: '/printing',
    name: 'printing',
    component: () => import('@/views/Printing'),
    meta: {
      permissions: 'sklad.tisk_stitku'
    },
  },

  {
    path: '/offline',
    name: 'offline',
    component: () => import('@/views/Offline')
  },

  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/Settings'),
    meta: {
      permissions: 'sklad.nastaveni'
    },
  },

  {
    path: '/product_diffs',
    name: 'productDiffs',
    component: () => import('@/views/ProductDiffs'),
    meta: {
      permissions: 'sklad.nesrovnalosti'
    },
  },

  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () => import('@/views/Unauthorized')
  },

  {
    path: '/appLoading',
    name: 'appLoading',
    component: () => import('@/views/AppLoading')
  },

  {
    path: '*',
    name: 'notFound',
    component: () => import('@/views/NotFound')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

const testEntry = (to) => {
  //Is app allowed
  if(!store.getters['Permissions/isAppAllowed']) return false;
  if(store.getters['Permissions/isBlacklistedUrl'](location.href)) return false;

  //If there are no permissions required, we allow the entry
  const routePermissions = to.meta.permissions;
  if(!routePermissions) return true;

  //If there are permissions required, we check if the user has them
  if(typeof routePermissions === 'string') {
    return store.getters['Permissions/clientHasPermission'](routePermissions);
  }

  //This is warehouse specific
  if(typeof routePermissions === 'object') {
    if(routePermissions[to.params.actionType]) {
      return store.getters['Permissions/clientHasPermission'](routePermissions[to.params.actionType]);
    } else {
      return false;
    }
  }
}


router.beforeEach((to, from, next) => {
    if(store.getters['User/getOnlineStatus'] === true) {
      if(to.name == "login") next();
      else if(to.name == "appLoading") next();
      else if(store.getters.isConfigLoaded) {
        if(to.name == "unauthorized") next();
        else if(testEntry(to)) next()
        else next({path: '/unauthorized'})
      } else {
        next({
            path: '/appLoading',
            query: {
                nextUrl: to.fullPath,
            }
        });
      }
    } else if(to.name !== "offline") {
      next('/offline');
    } else {
      next();
    }
});

export default router
