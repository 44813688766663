import { post, del, patch } from '@/services/api'

const state = {
  scannedProducts: [], // All products that I have scanned
  pipedProducts: [], // Products ready for "pípnutí" -> only changes
  pipedLocation: null, // Location for API call location_name
  lastValidScan: null, // Last code that got scanned

  fulltextSuggestions: [],
  multieanProducts: [],
};

const getters= {
  getScannedProducts: state => [...state.scannedProducts].reverse(),
  getScannedProductsByCode: state => code => state.scannedProducts.filter( p => p.variant.code == code ),
  getScannedProductsByEan: state => ean => state.scannedProducts.filter( p => p.variant.ean == ean ),
  getPipedProductByCode: state => code => state.pipedProducts.find( p => p.code == code ),
  getPipedProducts: state => state.pipedProducts,
  getPipedLocation: state => state.pipedLocation,
  getlastValidScan: state => state.lastValidScan,

  getFulltextSuggestions: state => state.fulltextSuggestions,
  getMultieanProducts: state => state.multieanProducts,
};

const actions= {
  clearScanning({ commit }) {
    commit("CLEAR_Scanned");
    commit("CLEAR_Piped");
    commit('CLEAR_PipedLocation');
  },

  addProductToScanned({ commit }, {product, inputCode}) {
    product.invalidLocation = false;
    commit("ADD_ScannedProduct", {product, inputCode});
  },

  commitInventoryPipni({ dispatch, getters }, {shoptet_sync, source = 'Sklad', otherData = {}, isAo = false}) {
    shoptet_sync = getters.getEshopConfig.is_public_api ? false : shoptet_sync;
    return new Promise((resolve, reject) => {
            let url = shoptet_sync ?  '/inventory/' : '/inventory/vratky'
            url = isAo ? '/inventory/ao/' : url; 
            post(url, {
              products: state.pipedProducts,
              location_name: state.pipedLocation,
              shoptet_sync: shoptet_sync,
              source,
              ...otherData})
            .then((res) => {
              if(!res.data.errors.length > 0) {
                  resolve({
                    response: res.data,
                    piped: getters.getPipedProducts,
                    location: getters.getPipedLocation
                  });
                  dispatch('clearScanning');
              } else {
                reject(res.data.errors);
              }
            });
    });
  },

  commitNewLocations({ dispatch }) {
      return new Promise((resolve, reject) => {
          patch('/inventory/', { products: state.pipedProducts, source: 'Sklad - přesun', location_name: state.pipedLocation}).then(() => {
              dispatch('clearScanning');
              resolve();
          }).catch((e) => reject(e));
      });
  },

  commitInventoryOdpipni({ dispatch, getters }, { sync, source = 'Sklad'}) {
    let url = sync ?  '/inventory/' : '/inventory/vratky'
    sync = getters.getEshopConfig.is_public_api ? false : sync;
  
    return new Promise((resolve, reject) => {
            del(url, { products: state.pipedProducts, location_name: state.pipedLocation, shoptet_sync: sync, source }).then((res) => {
              if(!res.data.errors.length > 0) {
                  dispatch('clearScanning');
                  resolve();
              } else {
                reject(res.data.errors);
              }
            });
    });
  },

  fetchFulltextSearch({ commit }, search) {
    return new Promise((resolve, reject) => {
            post(`/search/fulltext/`, { fulltext: search }).then((res) => {
                commit("SET_fulltext_suggestions", res.data);
                resolve(res.data);
            }).catch(() => {
                reject();
            });
    });
  },

  clearFulltext({ commit }) {
    commit("SET_fulltext_suggestions", []);
    commit("SET_MULTIEAN_PRODUCTS", []);
  },

  setLastProductCode({commit}, code) {
    commit("SET_lastCode", code);
  },

  setPipedLocation({commit}, name) {
    commit("SET_pipedLocation", name);
  },

  removeScannedByCode({ commit }, code) {
    if(code == state.lastValidScan) {
      commit("SET_lastCode", null);
    }
    commit("REMOVE_Scanned", code);
    commit("REMOVE_Pipped", code);
  },

  invalidateProductLocation({commit}, code) {
    commit("CHANGE_scannedProduct", code);
  },

  setLocationToMoveFrom({commit}, code) {
    commit("SET_locationToMoveFrom", code);
  },

  setLocationToMoveTo({commit}, code) {
    commit("SET_locationToMoveTo", code);
  },
};

const mutations= {
  CLEAR_Scanned: (state) => state.scannedProducts = [],
  CLEAR_Piped: (state) => state.pipedProducts = [],

  SET_lastCode: (state, code) => state.lastValidScan = code,

  CHANGE_scannedProduct: (state, product) => {
    product.invalidLocation = true;
    state.scannedProducts = [
       product,
       ...state.scannedProducts.filter(p => p.variant.code !== product.variant.code)
    ]
  },

  ADD_ScannedProduct: (state, {product, inputCode}) => {
    const scannedByProductCode = product.variant.code == inputCode; //Used for searching by product code or ean
    let index; //Used to determine whether the product has been scanned before and therefore is in the store already. Also used for UX sorting

    if(inputCode) {
      //Manual scan that is coming fom the ScanningInput component. We only need to match one, since it's likely the only one barcode on the product
      index = state.scannedProducts.findIndex(p => p.variant.code == product.variant.code) // Was item already scanned?
      if( index == -1 && product.multiean !== true &&  !scannedByProductCode && product.variant.ean !== null) index = state.scannedProducts.findIndex(p => p.variant.ean == product.variant.ean) // What about ean?
    } else {
      //Most likely a programatic scan we need to match both EAN and CODE. In some cases these they can be same for two products. This will ensure non-interchangeability
      index = state.scannedProducts.findIndex(scanned => scanned.variant.code == product.variant.code && scanned.variant.ean == product.variant.ean)
    }

    //The product was scanned before
    if( index > -1 ) {
      let pipIndex;

      if(scannedByProductCode) {
        pipIndex = state.pipedProducts.findIndex(p => p.code == inputCode);
      } else {
        pipIndex = state.pipedProducts.findIndex(p => p.ean == inputCode);
      }

      /* Multieans (eans with separators and custom products eans) won't match by the input code because it's not real code so we have to 
        match it by it's actual ean. This might still break in some edge edge edge cases */
      
      if(pipIndex == -1) {
        pipIndex = state.pipedProducts.findIndex(p => p.ean == product.variant.ean || p.code == product.variant.code);
      }

      const multiplier = product.variant.custom_quantity || 1;
      // Yes, we add temporary stock
      if(product.amount !== undefined)
        state.pipedProducts[pipIndex].amount = product.amount;
      else
        state.pipedProducts[pipIndex].amount += multiplier;

      let currentItem = state.scannedProducts[index];
      currentItem.variant.custom_quantity = multiplier;

      // LIFO -> scanned item moves to the top for better UX
      state.scannedProducts.splice(index, 1);
      state.scannedProducts.push(currentItem);
    } else {
      const multiplier = product.variant.custom_quantity !== null ? product.variant.custom_quantity : 1;
      // No we add it
      state.scannedProducts.push(product);
      state.pipedProducts.push({
        code: product.variant.code,
        ean: product.variant.ean,
        amount: product.amount !== undefined ? product.amount : multiplier,
        isMultiean: product.multiean
      });
    }
  },

  REMOVE_Scanned: (state, code) => {
    const index = state.scannedProducts.map(p => p.variant.code).indexOf(code);
    state.scannedProducts.splice(index, 1);
  },
  REMOVE_Pipped: (state, code) => {
    const index = state.pipedProducts.map(p => p.code).indexOf(code);
    state.pipedProducts.splice(index, 1);
  },

  SET_pipedLocation: (state, code) => state.pipedLocation = code,
  CLEAR_PipedLocation: (state) => state.pipedLocation = null,

  SET_fulltext_suggestions: (state, sugg) => state.fulltextSuggestions = sugg,
  SET_MULTIEAN_PRODUCTS: (state, products) => state.multieanProducts = products,
};



export default {
  //namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
