import Vue from 'vue'
import Vuex from 'vuex'

import Logs from './modules/Logs'
import StockTaking from './modules/StockTaking'
import Sectors from './modules/Sectors'
import LocationsChanges from './modules/LocationsChanges'
import Scanning from './modules/Scanning'
import ChaosRelocation from './modules/ChaosRelocation'
import ShoppingLists from './modules/ShoppingLists'
import MultiEan from './modules/MultiEan'
import Printers from './modules/Printers'
import Templates from './modules/Templates'
import Settings from './modules/Settings'

import RelocationRules from './modules/RelocationRules'
import SuggestedMoves from './modules/SuggestedMoves'
import DeletedProducts from './modules/DeletedProducts'
import ProductGroups from './modules/ProductGroups'

/*  UTILS  */
import User from './modules/utils/User'
import EshopConfig from './modules/utils/EshopConfig'
import PrintingSettings from './modules/utils/PrintingSettings'
import Permissions from './modules/utils/Permissions'
import ScanLoadingSpinner from './modules/utils/ScanLoadingSpinner'
import Dialog from './modules/utils/Dialog'
import Snackbar from './modules/utils/Snackbar'


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Logs,
    StockTaking,
    Sectors,
    LocationsChanges,
    Scanning,
    ChaosRelocation,
    ShoppingLists,
    RelocationRules,
    SuggestedMoves,
    MultiEan,
    Printers,
    Templates,
    Settings,

    EshopConfig,
    Snackbar,
    Dialog,
    User,
    PrintingSettings,
    DeletedProducts,
    ProductGroups,
    Permissions,
    ScanLoadingSpinner
  }
})
