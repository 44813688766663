<template>
  <v-snackbar
    top
    v-model="showSnack"
    :color="getSnack.type"
    :timeout="getSnack.timeout || -1"
    data-cy="snack-alert">
      <v-icon class="pr-3" dark large>{{ getIcon }}</v-icon>
      <span class="white--text">
        {{ getSnack.text }}
      </span>
      <template v-slot:action="{ attrs }" v-if="!getSnack.timeout">
        <v-btn
          text
          v-bind="attrs"
          @click="closeSnack">
          Zavřít
        </v-btn>
      </template>
    </v-snackbar>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  export default {
    name: 'Snackbar',
    computed: {
      ...mapGetters('Snackbar', ['getSnack']),
      getIcon() {
        const snackTypes = {
          'warning' : 'mdi-alert',
          'error' : 'mdi-alert',
          'info' : 'mdi-information',
        };
        if(this.getSnack.icon) return this.getSnack.icon;
        else if (this.getSnack.icon == false) return '';
        else return snackTypes[this.getSnack.type];
      },
      showSnack: {
        get() {
            return this.getSnack.text !== undefined;
          },
        set(){
            setTimeout( () => { this.closeSnack() }, 100); // This prevents the snackbar returning to default state before the animation finishes
          }
      },
    },
    methods: {
      ...mapActions('snackbar', ['closeSnack']),
    }
  }
</script>

<style scoped>

</style>
